import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import the default Quill theme

// Define the interface for props
interface IATextEditorProps {
  label?: string;
  value?: string; // Optional initial content
  onChange?: (data: string) => void; // Function to handle content change
}

const TextEditor2: React.FC<IATextEditorProps> = ({ label, value = '', onChange }) => {
  const [editorValue, setEditorValue] = useState<string>(value);

  useEffect(() => {
    // Update local state if the value prop changes
    setEditorValue(value);
  }, [value]);

  const handleChange = (content: string) => {
    setEditorValue(content);
    if (onChange) {
      onChange(content); // Call the onChange callback prop with the new content
    }
  };

  return (
    <div className={'flex flex-col mb-10'}>
      <div className={'text-text-secondary-invert text-[14px] text-[500] mb-[6px]'}>{label}</div>
      <ReactQuill
        className={'bg-text-white text-text-secondary-invert '}
        theme="snow"
        value={editorValue}
        onChange={handleChange}
        style={{ height: '300px' }} // Set editor height
      />
    </div>
  );
};

export default TextEditor2;
