const TypeTransactionList = [
  { UUID: 'SELL', name: 'SELL' },
  { UUID: 'BUY', name: 'BUY' },
  { UUID: 'LOCK', name: 'LOCK' },
  { UUID: 'DREAM GOLD', name: 'DREAM GOLD' },
  { UUID: 'COMMISSION', name: 'COMMISSION' },
  { UUID: 'TRANSFER', name: 'TRANSFER' },
  { UUID: 'PRINT', name: 'AMBIL EMAS' },
  { UUID: 'DCA', name: 'DCA' },
  { UUID: 'DEPOSIT', name: 'SETOR EMAS' },
  { UUID: 'ZAKAT', name: 'ZAKAT' }
];

export const TypeManualTransactionList = [
  { UUID: 'SELL', name: 'SELL' },
  { UUID: 'BUY', name: 'BUY' }
];
export default TypeTransactionList;
