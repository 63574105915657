import Typography from '@/delivery/components/atoms/typography/typography.tsx';
import { FaArrowLeft } from 'react-icons/fa6';
import { useNavigate, useParams } from 'react-router-dom';
import { Routing } from '@/domain/constant/router_path.ts';
import { useEffect } from 'react';
import { usePPOBContext } from '@/delivery/ui/admin/settings/ppob/context/ppob_context.tsx';
import { Table, Tbody, Td, Th, Thead, Theader, Tr } from '@/delivery/components/molecules/table/table.tsx';
import FilterPPOB from '@/delivery/ui/admin/settings/ppob/filter_ppob.tsx';
import { PPOBModel } from '@/domain/model/ppob_model.ts';
import Chips from '@/delivery/components/atoms/chips/chips.tsx';
import STATUS_CONSTANT from '@/domain/constant/status_constanat.ts';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import DialogAddMargin from '@/delivery/ui/admin/settings/ppob/detail/dialog/dialog_add_margin.tsx';

const DetailPPOB = () => {
  const navigate = useNavigate();
  const { vendor_uid, ppob_product_code } = useParams();
  const { handleCreatePPOBMarginModal, ppobMargins, handleFetchPPOBMargin } = usePPOBContext();

  useEffect(() => {
    if (vendor_uid && ppob_product_code) {
      handleFetchPPOBMargin({ vendor_uid: vendor_uid, ppob_product_code: ppob_product_code });
    }
  }, [vendor_uid, ppob_product_code]);

  return (
    <div className={'mt-[24px] flex flex-col gap-[20px]'}>
      <div
        className={'flex gap-[10px] items-center text-text-primary cursor-pointer'}
        onClick={() => navigate(Routing.SETTINGS.PPOB)}
      >
        <FaArrowLeft />
        <Typography size={14} fontWeight={500}>
          Back to PPOB Product
        </Typography>
      </div>
      <div>
        <Typography size={24} fontWeight={600}>
          PPOB Margin
        </Typography>
      </div>
      <div className={'flex'}>
        <div className={'w-1/4 flex-col flex'}>
          <div>
            <Typography size={16} fontWeight={400} type={'secondary-invert'}>
              Vendor
            </Typography>
          </div>
          <div>
            <Typography size={16} fontWeight={600} type={'secondary-invert'}>
              {vendor_uid ?? '-'}
            </Typography>
          </div>
        </div>

        <div className={'w-3/4 flex-col flex'}>
          <div>
            <Typography size={16} fontWeight={400} type={'secondary-invert'}>
              Code
            </Typography>
          </div>
          <div>
            <Typography size={16} fontWeight={600} type={'secondary-invert'}>
              {ppob_product_code}
            </Typography>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-[20px]">
        <div>
          <Theader>
            <Typography size={16} fontWeight={600} type={'secondary-invert'}>
              Table PPOB Margin
            </Typography>
            <div className={'flex gap-[20px]'}>
              <FilterPPOB />
              <Button
                label={'Add Margin'}
                prefixIcon={Icon.plus}
                onClick={() => handleCreatePPOBMarginModal.setState(true)}
              />
            </div>
          </Theader>
          <Table noBorder className="rounded-b-[8px]">
            <Thead>
              <Tr>
                <Th>Code</Th>
                <Th>Type</Th>
                <Th>Name</Th>
                <Th>Status</Th>
                <Th>Base Price</Th>
                <Th>Price</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {ppobMargins.length !== 0 ? (
                <>
                  {ppobMargins?.map((item: PPOBModel, index: number) => (
                    <Tr>
                      <Td>
                        <Typography fontWeight={400} size={14} type="tertiary-invert">
                          {item.code}
                        </Typography>
                      </Td>
                      <Td>
                        <Typography fontWeight={400} size={14} type="tertiary-invert">
                          {item.type}
                        </Typography>
                      </Td>
                      <Td>
                        <Typography fontWeight={400} size={14} type="tertiary-invert">
                          {item.name}
                        </Typography>
                      </Td>

                      <Td>
                        <Chips
                          type={item.status === STATUS_CONSTANT.active ? 'primary-light' : 'danger-light'}
                          label={item.status}
                        />
                      </Td>
                      <Td>
                        <Typography fontWeight={400} size={14} type="tertiary-invert">
                          {item.getBasePriceRupiah}
                        </Typography>
                      </Td>
                      <Td>
                        <Typography fontWeight={400} size={14} type="tertiary-invert">
                          {item.getPriceRupiah}
                        </Typography>
                      </Td>
                    </Tr>
                  ))}
                </>
              ) : (
                <Tr>
                  <Td colSpan={7}>
                    <Typography size={12} style={'italic'}>
                      Filter untuk menampilkan data
                    </Typography>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </div>
      </div>

      {handleCreatePPOBMarginModal.state && <DialogAddMargin modal={handleCreatePPOBMarginModal} />}
    </div>
  );
};

export default DetailPPOB;
