import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import React, { createContext, useContext, ReactNode, useState, Dispatch, SetStateAction } from 'react';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import { PaginationModel } from '@/domain/model/pagination_model.ts';
// import useAlertSweetComponent from '@/delivery/hooks/useSweetAlertComponent.tsx';
import { PPOBRepository } from '@/domain/repository/ppob_repository.ts';
import { PPOBModel } from '@/domain/model/ppob_model.ts';

interface ppobContextContextProps {
  children: ReactNode;
}

interface PPOBValue {
  uid?: string;
  page?: number;
  limit?: number;
  type?: string | null;
  status?: string | null;
  operator?: string | null;
  sub_type?: string | null;
  ppob_product_uuid?: string;
  vendor_uid?: string;
  ppob_product_code?: string;
}

interface FormPPOBMarginInterface {
  discount_flat?: number;
  discount_percentage?: number;
  margin_flat?: number;
  margin_percentage?: number;
  ppob_product_code?: string;
  ppob_product_uid?: string;
  status?: string;
  vendor_uid?: string;
  type?: string | null;
}

interface FormPPOBValue {
  type?: string;
  status?: string;
  operator?: string;
  sub_type?: string;
}

interface ppobContextValue {
  formPPOBMargin: FormPPOBMarginInterface;
  setFormPPOBMargin: Dispatch<SetStateAction<FormPPOBMarginInterface>>;
  ppobMargins: PPOBModel[];
  ppobMarginPagination?: PaginationModel;
  formPPOB: FormPPOBValue;
  setFormPPOB: Dispatch<SetStateAction<FormPPOBValue>>;
  ppobProducts: PPOBModel[];
  handleCreatePPOBMarginModal: ModalObject;
  handleFetchPPOBProduct: (_data: PPOBValue) => void;
  handleFetchPPOBMargin: (_data: PPOBValue) => void;
  handleAddPPOBMargin: (_data: PPOBValue) => void;
}

const MemberVerifyContext = createContext<ppobContextValue | null>(null);

const ppobRepository = new PPOBRepository();

const PPOBProvider: React.FC<ppobContextContextProps> = ({ children }) => {
  const alertToast = useAlertToast();
  // const alertSweet = useAlertSweetComponent();

  const handleCreatePPOBMarginModal = useVisibleComponent(false);

  const [ppobProducts, setPPOBProducts] = useState<PPOBModel[]>([]);

  const [ppobMargins, setPPOBMargins] = useState<PPOBModel[]>([]);
  const [ppobMarginPagination, setPPOBMarginPagination] = useState<PaginationModel>();

  const [formPPOB, setFormPPOB] = useState<FormPPOBValue>({
    type: '',
    operator: '',
    status: ''
  });
  const [formPPOBMargin, setFormPPOBMargin] = useState<FormPPOBMarginInterface>({});

  //HANDLE FETCH ppob
  const handleFetchPPOBProduct = (_data: PPOBValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      type: formPPOB.type ?? null,
      status: formPPOB.status ?? null,
      operator: formPPOB.operator ?? null,
      sub_type: formPPOB.sub_type ?? null
    };
    ppobRepository
      .fetchPPOB(params)
      .then((result: any) => {
        console.log(result, 'result');
        setPPOBProducts(result);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.errorAlert(error);
        alertToast.updateLoading(x);
      });
  };

  //HANDLE FETCH PPOB MARGIN
  const handleFetchPPOBMargin = (_data: PPOBValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      vendor_uid: _data.vendor_uid,
      ppob_product_code: _data.ppob_product_code
    };

    ppobRepository
      .fetchPPOBMargin(params)
      .then((res: any) => {
        setPPOBMargins(res.data);
        setPPOBMarginPagination(res.pagination);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  //HANDLE ADD PPOB MMARGIN
  const handleAddPPOBMargin = (_data: PPOBValue) => {
    const x = alertToast.loadingAlert('');

    const params = {
      discount_flat: formPPOBMargin.discount_flat,
      discount_percentage: formPPOBMargin.discount_percentage,
      margin_flat: formPPOBMargin.margin_flat,
      margin_percentage: formPPOBMargin.margin_percentage,
      ppob_product_code: formPPOBMargin.ppob_product_code,
      ppob_product_uid: formPPOBMargin.ppob_product_uid,
      status: formPPOBMargin.status,
      vendor_uid: formPPOBMargin.vendor_uid
    };

    ppobRepository
      .storePPOBMargin(params)
      .then((res: any) => {
        handleFetchPPOBMargin({ ppob_product_uuid: _data.ppob_product_uuid });
        handleCreatePPOBMarginModal.setState(false);
        alertToast.updateLoading(x);
      })
      .catch(error => {
        alertToast.updateLoading(x);
        alertToast.errorAlert(error);
      });
  };

  const contextValue: ppobContextValue = {
    formPPOBMargin,
    setFormPPOBMargin,
    formPPOB,
    setFormPPOB,
    ppobProducts,
    handleCreatePPOBMarginModal,
    handleFetchPPOBProduct,
    handleFetchPPOBMargin,
    ppobMargins,
    ppobMarginPagination,
    handleAddPPOBMargin
  };

  return <MemberVerifyContext.Provider value={contextValue}>{children}</MemberVerifyContext.Provider>;
};

const usePPOBContext = (): ppobContextValue => {
  const context = useContext(MemberVerifyContext);
  if (!context) {
    throw new Error('usePPOBContext must be used within a PPOBProvider');
  }
  return context;
};

export { PPOBProvider, usePPOBContext };
