import Button from '@/delivery/components/atoms/button/button';
import TextField from '@/delivery/components/atoms/text_field/text_field';
import dinaranLogo from '@/delivery/assets/dinaran-logo.png';
import { Image } from '@/delivery/components/atoms/image/image';
import { IoMdArrowBack } from 'react-icons/io';

import { useState } from 'react';
import DialogForgotPassword from './forgot_password/forgot_password_dialog';
import { useAuthContext } from '@/delivery/ui/login/context/auth_context.tsx';
import { getSendEmailOTP, removeSendEmailOTP } from '@/infrastructure/helper/auth.ts';
import { COMPANY_UUID } from '@/domain/constant/company/company_uuid.ts';

const LoginOTP = () => {
  const { excLoginOTP, loginOTP, loading, handleForgotPasswordModal } = useAuthContext();

  const [email, setEmail] = useState<string>('');
  const [otp, setOTP] = useState<string>('');

  const handleKeyDownLoginOTP = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      loginOTP({ email: email });
    }
  };

  const handleKeyDownExcLoginOTP = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      excLoginOTP({ otp: otp, username: email, company_uuid: COMPANY_UUID });
    }
  };

  const handleBackToEmail = () => {
    removeSendEmailOTP();
    location.reload();
  };

  return (
    <div className="flex justify-center items-center h-[100vh]">
      <div className="flex items-center flex-col gap-[20px] lg:w-[420px] md:w-[420px] sm:lg:w-full border border-border-tertiary p-[40px] rounded-[20px]">
        <div className="mb-[40px]">
          <Image width={250} url={dinaranLogo} />
        </div>
        {getSendEmailOTP() === null ? (
          <div className={'w-full'}>
            <TextField
              value={email}
              block
              label="Email"
              onChange={e => setEmail(e.target.value)}
              onKeyDown={handleKeyDownLoginOTP}
            />

            <div className="mt-[30px] w-full">
              <Button
                loading={loading}
                block
                label="Login"
                onKeyUp={() =>
                  loginOTP({
                    email: email
                  })
                }
                onClick={() => {
                  loginOTP({
                    email: email
                  });
                }}
              />
            </div>
          </div>
        ) : (
          <div className={'w-full'}>
            <TextField
              value={otp}
              block
              label="OTP"
              onKeyDown={handleKeyDownExcLoginOTP}
              onChange={e => setOTP(e.target.value)}
            />

            <div className="mt-[30px] w-full flex gap-1">
              <Button prefixIcon={<IoMdArrowBack />} onClick={() => handleBackToEmail()} />
              <Button
                loading={loading}
                block
                label="Send OTP"
                onKeyUp={() =>
                  excLoginOTP({
                    otp: otp,
                    username: email,
                    company_uuid: COMPANY_UUID
                  })
                }
                onClick={() =>
                  excLoginOTP({
                    otp: otp,
                    username: email,
                    company_uuid: COMPANY_UUID
                  })
                }
              />
            </div>
          </div>
        )}
      </div>

      {handleForgotPasswordModal.state && <DialogForgotPassword modal={handleForgotPasswordModal} />}
    </div>
  );
};

export default LoginOTP;
