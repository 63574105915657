import useAlertToast from '@/delivery/hooks/useAlertComponent';
import useVisibleComponent from '@/delivery/hooks/useVisibleComponent';
import { ModalObject } from '@/delivery/interface/modal_interface';
import { Routing } from '@/domain/constant/router_path';
import { AuthRepository } from '@/domain/repository/auth_repository';
import { AuthValue } from '@/domain/repository/interface/auth_interface';
import { setToken, setAccess, removeToken, setSendEmailOTP, removeSendEmailOTP } from '@/infrastructure/helper/auth';
import React, { createContext, useContext, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MEMBER_TYPE } from '@/domain/constant/member/member_type.ts';
import { UserModel } from '@/domain/model/user_model.ts';

interface DialogContextProps {
  children: ReactNode;
}

interface DialogContextValue {
  //   handleCreateProductModal: ModalObject;
  //   handleEditProductModal: ModalObject;
  loading: boolean;
  login: (_data: AuthValue) => void;
  handleResetPassword: (_data: AuthValue) => void;
  handleLogOut: () => void;
  handleForgotPasswordModal: ModalObject;
  handleConfirmLogoutModal: ModalObject;
  handleAddBaseUrlModal: ModalObject;
  handleConfirmLogout: () => void;
  loginOTP: (_data: AuthValue) => void;
  excLoginOTP: (_data: AuthValue) => void;
}

const AuthContext = createContext<DialogContextValue | null>(null);

const AuthProvider: React.FC<DialogContextProps> = ({ children }) => {
  //   const handleCreateProductModal = useVisibleComponent(false);
  //   const handleEditProductModal = useVisibleComponent(false);
  const handleForgotPasswordModal = useVisibleComponent(false);
  const handleConfirmLogoutModal = useVisibleComponent(false);
  const handleAddBaseUrlModal = useVisibleComponent(false);

  const navigate = useNavigate();
  const alertToast = useAlertToast();

  const auth = new AuthRepository();

  const [loading, setLoading] = useState<boolean>(false);

  const getUserAccess = async () => {
    return auth
      .userAccess()
      .then((res: any) => {
        return setAccess(JSON.stringify(res.data));
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //ACCESS CONTROLLER
  // const getAccessController = () => {
  //   return accessController
  //     .fetchAccessController()
  //     .then((result: any) => {
  //       setAccess(result);
  //     })
  //     .catch(error => {
  //       alertToast.errorAlert(error);
  //     });
  // };

  //DO LOGIN
  const login = async (_data: AuthValue) => {
    try {
      setLoading(true);
      const result: any = await auth.login(_data);
      setToken(result.access_token);
      setLoading(false);
      await getUserAccess();
      // getAccessController();
      navigate(Routing.DASHBOARD.OVERVIEW, { replace: true });
      navigate(0);
    } catch (error: any) {
      setLoading(false);
      alertToast.errorAlert(error);
    }
  };

  const loginOTP = (_data: AuthValue) => {
    setLoading(true);
    const params = {
      email: _data.email
    };

    auth
      .authInquiry(params)
      .then((result: any) => {
        const res: UserModel = result;

        if (
          res.type.toUpperCase() === MEMBER_TYPE.ADMIN ||
          res.type.toUpperCase() === MEMBER_TYPE.CORPORATE ||
          res.type.toUpperCase() === MEMBER_TYPE.SUPER
        ) {
          auth
            .loginOTP(params)
            .then((result: any) => {
              setSendEmailOTP('true');
              setLoading(false);
              alertToast.successAlert(result);
            })
            .catch(error => {
              setLoading(false);
              alertToast.errorAlert(error);
            });
        } else {
          setLoading(false);
          alertToast.errorAlert('user not found');
        }
      })
      .catch(error => {
        setLoading(false);
        alertToast.errorAlert(error);
      });
    //   } else {
    //     setLoading(false);
    //     alertToast.errorAlert('user not found');
    //   }
    // })
    // .catch(error => {
    //   setLoading(false);
    //   alertToast.errorAlert(error);
    // });
  };

  const excLoginOTP = async (_data: AuthValue) => {
    try {
      setLoading(true);
      const result: any = await auth.excLoginOTP(_data);
      setToken(result.access_token);
      setLoading(false);
      await getUserAccess();
      // getAccessController();

      if (result?.user.type == MEMBER_TYPE.CORPORATE) {
        await navigate(Routing.CORPORATE, { replace: true });
      } else {
        await navigate(Routing.DASHBOARD.OVERVIEW, { replace: true });
      }

      removeSendEmailOTP();
      navigate(0);
    } catch (error: any) {
      setLoading(false);
      alertToast.errorAlert(error);
    }
  };

  //RESET PASSWORD
  const handleResetPassword = (_data: AuthValue) => {
    const params = {
      email: _data.email,
      password: _data.password
    };

    auth
      .resetPassword(params)
      .then(result => {
        handleForgotPasswordModal.setState(false);
      })
      .catch(error => {
        alertToast.errorAlert(error);
      });
  };

  //CONFIRM LOGOUT
  const handleConfirmLogout = () => {
    handleConfirmLogoutModal.setState(true);
  };

  //LOGOUT
  const handleLogOut = async () => {
    const x = alertToast.loadingAlert('');

    try {
      await removeToken();
      navigate(Routing.LOGIN, { replace: true });
      navigate(0);
      removeSendEmailOTP();
      alertToast.updateLoading(x);
    } catch (error: any) {
      alertToast.updateLoading(x);
      alertToast.errorAlert(error);
    }
  };

  const contextValue: DialogContextValue = {
    loginOTP,
    // handleCreateProductModal,
    // handleEditProductModal,
    handleConfirmLogout,
    handleConfirmLogoutModal,
    handleLogOut,
    handleResetPassword,
    handleForgotPasswordModal,
    handleAddBaseUrlModal,
    loading,
    login,
    excLoginOTP
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

const useAuthContext = (): DialogContextValue => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useDialogContext must be used within a DialogProvider');
  }
  return context;
};

export { AuthProvider, useAuthContext };
