import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import {
  ModalDialog,
  ModalDialogFooter,
  ModalDialogProps
} from '@/delivery/components/molecules/modal_dialog/modal_dialog.tsx';
import Button from '@/delivery/components/atoms/button/button.tsx';
import TypeFee, { TypeFeeConst } from '@/domain/constant/type_fee.tsx';
import { useEffect } from 'react';
import { usePPOBContext } from '@/delivery/ui/admin/settings/ppob/context/ppob_context.tsx';
import { useParams } from 'react-router-dom';

const DialogAddMargin = (props: ModalDialogProps) => {
  const { vendor_uid, ppob_product_code } = useParams();
  const { handleAddPPOBMargin, handleCreatePPOBMarginModal, formPPOBMargin, setFormPPOBMargin } = usePPOBContext();

  useEffect(() => {
    setFormPPOBMargin({});
  }, []);

  return (
    <ModalDialog size="small" title="Add Margin" modal={props.modal}>
      <div className="flex flex-col gap-[20px]">
        <TextField
          label={'PPOB Code'}
          block
          onChange={(e: any) => {
            setFormPPOBMargin({
              ...formPPOBMargin,
              ppob_product_code: e.target.value
            });
          }}
        />
        <TextField
          label={'Vendor'}
          block
          onChange={(e: any) => {
            setFormPPOBMargin({
              ...formPPOBMargin,
              vendor_uid: e.target.value
            });
          }}
        />
        <Select2
          label={'Type'}
          options={TypeFee.map(item => {
            return {
              label: item.name,
              value: item.UUID
            };
          })}
          onChange={e => setFormPPOBMargin({ ...formPPOBMargin, type: e.value })}
        />

        {formPPOBMargin.type === TypeFeeConst.FLAT ? (
          <>
            <TextField
              type={'currency'}
              label={'Discount (Rp)'}
              block
              onChange={(e: any) => {
                setFormPPOBMargin({
                  ...formPPOBMargin,
                  discount_flat: e.floatValue
                });
              }}
            />
            <TextField
              type={'currency'}
              label={'Margin (Rp)'}
              block
              onChange={(e: any) => {
                setFormPPOBMargin({
                  ...formPPOBMargin,
                  margin_flat: e.floatValue
                });
              }}
            />
          </>
        ) : (
          ''
        )}

        {formPPOBMargin.type === TypeFeeConst.PERCENT ? (
          <>
            <TextField
              type={'number'}
              label={'Discount (%)'}
              block
              onChange={(e: any) => {
                setFormPPOBMargin({
                  ...formPPOBMargin,
                  discount_percentage: parseInt(e.target.value)
                });
              }}
            />
            <TextField
              type={'number'}
              label={'Margin (%)'}
              block
              onChange={(e: any) => {
                setFormPPOBMargin({
                  ...formPPOBMargin,
                  margin_percentage: parseInt(e.target.value)
                });
              }}
            />
          </>
        ) : (
          ''
        )}
      </div>
      <ModalDialogFooter className="flex justify-between gap-[20px]">
        <Button block type="secondary" label="Cancel" onClick={() => handleCreatePPOBMarginModal.setState(false)} />
        <Button
          block
          label="Save"
          onClick={() => handleAddPPOBMargin({ ppob_product_code: ppob_product_code, vendor_uid: vendor_uid })}
        />
      </ModalDialogFooter>
    </ModalDialog>
  );
};

export default DialogAddMargin;
