import ButtonFilter from '@/delivery/components/atoms/button_filter/button_filter.tsx';
import TextField from '@/delivery/components/atoms/text_field/text_field.tsx';
import Datepicker from '@/delivery/components/atoms/date/date.tsx';
import {
  FormFilterTransaction,
  useTransactionListContext
} from '@/delivery/ui/admin/transaction/transaction_list/context/transaction_list_context.tsx';
import Select2 from '@/delivery/components/atoms/select2/select2.tsx';
import TypeTransactionList from '@/domain/constant/transaction/type_transaction_list.ts';
import { STATUS_TRANSACTION_LIST } from '@/domain/constant/transaction/status_transaction.ts';
import { useEffect, useRef, useState } from 'react';
import Button from '@/delivery/components/atoms/button/button.tsx';
import Icon from '@/domain/constant/icon.tsx';
import DialogExportTransaction from '@/delivery/ui/admin/transaction/transaction_list/dialog/dialog_export_data_transaction_list.tsx';
import useAlertToast from '@/delivery/hooks/useAlertComponent.tsx';
import Dexie from 'dexie';
import { DATABASE_NAME } from '@/domain/constant/indexed_db/indexed_db.ts';
import { DBTransaction, TransactionExportInt } from '@/infrastructure/db/transaction_db.ts';
import { TYPE_TRANSACTION } from '@/domain/constant/transaction/type_transaction.ts';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { formatDateTimeParam } from '@/infrastructure/helper/formatDate.ts';

const FilterTransactionList = () => {
  const tableRef = useRef(null);
  const alertToast = useAlertToast();
  const {
    handleExportTransactionModal,
    // handleExportTransactionList,
    handleExportTransactionList,
    transactionListPagination,
    handleFetchTransactionList,
    formFilterTransaction,
    setFormFilterTransaction
  } = useTransactionListContext();

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: `transaction-list-${formatDateTimeParam(new Date())}`,
    sheet: 'transaction'
  });

  const [isResetting, setIsResetting] = useState(false);

  const handleReset = () => {
    setFormFilterTransaction({
      search: '',
      status: null,
      type: null,
      transaction_at_start: '',
      transaction_at_end: ''
    });
    setIsResetting(true);
  };

  useEffect(() => {
    if (isResetting) {
      handleFetchTransactionList({ page: 1, limit: 10 });
      setIsResetting(false);
    }
  }, [setFormFilterTransaction, isResetting]);

  function isFormFilterFilled(formFilterTransaction: FormFilterTransaction): boolean {
    return Object.values(formFilterTransaction).some(value => value !== undefined && value !== null && value !== '');
  }

  const [dataExport, setDataExport] = useState<TransactionExportInt[]>([]);

  // GET DATA EXPORT TO INDEX DB
  const handleSetDataExportToIndexedDB = async () => {
    if (transactionListPagination) {
      const x = alertToast.loadingAlert('Preparing data');

      for (let x = 0; x < transactionListPagination?.totalPages; x++) {
        await handleExportTransactionList({ page: x + 1, limit: 10 });
      }
      alertToast.updateLoading(x);
      handleSetDataExport();
    }
  };

  //SET DATA EXPORT TO STATE
  const handleSetDataExport = async () => {
    const db = new DBTransaction();

    try {
      const transactions = await db.transactionExport.toArray();
      setDataExport(transactions.reverse());
    } catch (error) {
      console.error('Failed to fetch transactions:', error);
    }
  };

  //HANDLE EXPORT DATA ACTION
  useEffect(() => {
    if (dataExport.length > 0) {
      onDownload();
      setTimeout(() => {
        handleDropDatabase();
      }, 2000);
    }
  }, [dataExport]);

  //DELETE DATABASE INDEXED DB
  const handleDropDatabase = () => {
    const db = new Dexie(DATABASE_NAME);
    db.delete();
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row gap-[12px]">
        <Button type={'secondary'} prefixIcon={Icon.reset} label={'Reset Filter'} onClick={handleReset} />
        <ButtonFilter
          onClickApply={() => handleFetchTransactionList({})}
          size={'small'}
          titleFilter={'Filter Transaction'}
          label={'More Filter'}
        >
          <div className={'flex flex-col gap-[20px]'}>
            <TextField
              value={formFilterTransaction.search}
              search
              placeholder={'Search...'}
              block
              onChange={e => setFormFilterTransaction({ ...formFilterTransaction, search: e.target.value })}
            />

            <div className={'grid grid-cols-2 gap-[20px]'}>
              <Select2
                isClearable
                value={formFilterTransaction.status}
                isSearchable={false}
                options={STATUS_TRANSACTION_LIST.map(item => {
                  return {
                    label: item.name,
                    value: item.UUID
                  };
                })}
                block
                label={'Status'}
                onChange={e => setFormFilterTransaction({ ...formFilterTransaction, status: e?.value })}
              />
              <Select2
                isClearable
                value={formFilterTransaction.type}
                isSearchable={false}
                options={TypeTransactionList.map(item => {
                  return {
                    label: item.name,
                    value: item.UUID
                  };
                })}
                block
                label={'Type'}
                onChange={e => setFormFilterTransaction({ ...formFilterTransaction, type: e?.value })}
              />
              <Datepicker
                value={formFilterTransaction.transaction_at_start}
                label={'Start Date'}
                block
                onChange={e =>
                  setFormFilterTransaction({
                    ...formFilterTransaction,
                    transaction_at_start: e
                  })
                }
              />
              <Datepicker
                value={formFilterTransaction.transaction_at_end}
                label={'End Date'}
                block
                onChange={e =>
                  setFormFilterTransaction({
                    ...formFilterTransaction,
                    transaction_at_end: e
                  })
                }
              />
            </div>
          </div>
        </ButtonFilter>
        <Button
          disabled={isFormFilterFilled(formFilterTransaction) ? false : true}
          type={'secondary'}
          prefixIcon={Icon.downloadCloud}
          label={'Export Filter'}
          // onClick={() => handleExportTransactionList()}
          onClick={() => handleSetDataExportToIndexedDB()}
        />
        {/*<Button label="Export by Filter" type="secondary" prefixIcon={<FiDownloadCloud />} />*/}

        {handleExportTransactionModal.state && <DialogExportTransaction modal={handleExportTransactionModal} />}
      </div>

      <table ref={tableRef} className={'border border-solid hidden'}>
        <tbody>
          <tr>
            <th className={'border border-solid'}>TRX ID</th>
            <th className={'border border-solid'}>Nama</th>
            <th className={'border border-solid'}>Email</th>
            <th className={'border border-solid'}>Type</th>
            <th className={'border border-solid'}>Reversal</th>

            <th className={'border border-solid'}>Status</th>
            <th className={'border border-solid'}>Trx Date</th>
            <th className={'border border-solid'}>Paid Date</th>
            <th className={'border border-solid'}>Paid Amount</th>
            <th className={'border border-solid'}>Fee</th>
            <th className={'border border-solid'}>Coin</th>
            <th className={'border border-solid'}>Vendor</th>
            <th className={'border border-solid'}>Vendor Ref ID</th>
            <th className={'border border-solid'}>Gramasi</th>
            <th className={'border border-solid'}>Buy Rate</th>
            <th className={'border border-solid'}>Sell Rate</th>
            <th className={'border border-solid'}>Pengirim</th>
            <th className={'border border-solid'}>Email Pengirim</th>
            <th className={'border border-solid'}>Penerima</th>
            <th className={'border border-solid'}>Email Penerima</th>
          </tr>

          {dataExport.map((item: TransactionExportInt, index) => (
            <tr>
              <td className={'border border-solid'}>{item.trx_id}</td>
              <td className={'border border-solid'}>{item.name}</td>
              <td className={'border border-solid'}>{item.email}</td>
              <td className={'border border-solid'}>{item.type}</td>
              <td className={'border border-solid'}>{item.reversal}</td>
              <td className={'border border-solid'}>
                {item.reversal && item.type === TYPE_TRANSACTION.REVERSAL ? (
                  <span>{`${item.status} ${item.type}`}</span>
                ) : (
                  <span>{item.status}</span>
                )}
              </td>
              <td className={'border border-solid'}>{item.trx_date}</td>
              <td className={'border border-solid'}>{item.paid_date}</td>
              <td className={'border border-solid'}>{item.paid_amount}</td>
              <td className={'border border-solid'}>{item.fee}</td>
              <td className={'border border-solid'}>{item.coin}</td>
              <td className={'border border-solid'}>{item.vendor}</td>
              <td className={'border border-solid'}>{item.vendor_ref_id}</td>
              <td className={'border border-solid'}>{item.amount_gram}</td>
              <td className={'border border-solid'}>
                <span>{item.rate_buy}</span>
              </td>
              <td className={'border border-solid'}>
                <span>{item.rate_sell}</span>
              </td>
              <td className={'border border-solid'}>{item.sender}</td>
              <td className={'border border-solid'}>{item.sender_email}</td>
              <td className={'border border-solid'}>{item.receiver}</td>
              <td className={'border border-solid'}>{item.receiver_email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FilterTransactionList;
