const PPOBList = [
  { UUID: 'PPOB', name: 'PPOB' },
  { UUID: 'PULSA', name: 'PULSA' },
  { UUID: 'GAME', name: 'GAME' }
];

const PPOBTypeConst = {
  PPOB: 'PPOB',
  PULSA: 'PULSA',
  GAME: 'GAME'
};

export { PPOBList, PPOBTypeConst };
